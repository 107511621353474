import { useMutation, useQuery } from '@tanstack/react-query';
import Api from '../Api';
import { ApiRoutes } from '../ApiRoutes';

interface CreateNpsResponseDto {
  score: number;
  feedback?: string;
}

interface NpsResponse {
  id: string;
  score: number;
  feedback?: string;
  createdAt: string;
  user: {
    id: string;
    email: string;
    firstName?: string;
    lastName?: string;
  };
}

interface NpsStats {
  totalResponses: number;
  averageScore: number;
  promoters: number;
  passives: number;
  detractors: number;
  npsScore: number;
}

interface GetNpsParams {
  startDate?: Date;
  endDate?: Date;
}

const createNpsResponse = async (dto: CreateNpsResponseDto) => {
  await Api.ky.post(`${ApiRoutes.Survey}/nps`, { json: dto });
};

export const useCreateNpsResponseMutation = () => {
  return useMutation({
    mutationFn: createNpsResponse,
  });
};

const getNpsResponses = async ({ startDate, endDate }: GetNpsParams = {}) => {
  const searchParams = new URLSearchParams();
  if (startDate) {
    searchParams.set('startDate', startDate.toISOString());
  }
  if (endDate) {
    searchParams.set('endDate', endDate.toISOString());
  }

  const response = await Api.ky.get(`${ApiRoutes.Survey}/admin/nps/responses`, {
    searchParams,
  });
  return response.json<NpsResponse[]>();
};

export const useNpsResponses = (params: GetNpsParams = {}) => {
  return useQuery({
    queryKey: ['nps-responses', params],
    queryFn: () => getNpsResponses(params),
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    gcTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
  });
};

const getNpsStats = async ({ startDate, endDate }: GetNpsParams = {}) => {
  const searchParams = new URLSearchParams();
  if (startDate) {
    searchParams.set('startDate', startDate.toISOString());
  }
  if (endDate) {
    searchParams.set('endDate', endDate.toISOString());
  }

  const response = await Api.ky.get(`${ApiRoutes.Survey}/admin/nps/stats`, {
    searchParams,
  });
  return response.json<NpsStats>();
};

export const useNpsStats = (params: GetNpsParams = {}) => {
  return useQuery({
    queryKey: ['nps-stats', params],
    queryFn: () => getNpsStats(params),
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    gcTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
  });
};
