import {
  Button,
  Container,
  Paper,
  TextInput,
  Title,
  Stack,
  Tabs,
  Group,
  ThemeIcon,
  Text,
  Alert,
  Modal,
  ActionIcon,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import { AppRouteURL } from '../../AppRouteURL';
import { getLogger } from '../../services/Logger';
import { NPSAnalytics } from '../../components/NPSAnalytics/NPSAnalytics';
import {
  IconUsers,
  IconChartBar,
  IconTrash,
  IconAlertCircle,
  IconInfoCircle,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

const log = getLogger('AdminPage');

const AdminPage: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [opened, { open, close }] = useDisclosure(false);

  const handleDeleteUser = () => {
    setDeleteError(null);

    Api.ky
      .delete(ApiRoutes.Users, { json: { email } })
      .then(() => {
        close();
        setEmail('');
      })
      .catch((error) => {
        log.error(error);
        if (error instanceof Error || typeof error === 'string') {
          setDeleteError(error.toString());
        }
      });
  };

  return (
    <Container size="xl">
      <Stack gap="xl">
        <Group justify="space-between" align="center">
          <Title order={2}>{t('admin.title', 'Administration')}</Title>
          <Tooltip label={t('admin.help', 'Admin Panel Help')}>
            <ActionIcon
              variant="light"
              color="blue"
              size="lg"
              radius="xl"
              aria-label="Help"
            >
              <IconInfoCircle style={{ width: '70%', height: '70%' }} />
            </ActionIcon>
          </Tooltip>
        </Group>

        <Tabs defaultValue="users" variant="outline">
          <Tabs.List>
            <Tabs.Tab
              value="users"
              leftSection={<IconUsers size={16} />}
              fw={500}
            >
              {t('admin.tabs.users', 'User Management')}
            </Tabs.Tab>
            <Tabs.Tab
              value="nps"
              leftSection={<IconChartBar size={16} />}
              fw={500}
            >
              {t('admin.tabs.nps', 'NPS Analytics')}
            </Tabs.Tab>
          </Tabs.List>

          <Paper mt="xl">
            <Tabs.Panel value="users">
              <Stack gap="lg">
                <Group>
                  <Button
                    component={Link}
                    to={AppRouteURL.adminUser}
                    variant="light"
                    leftSection={<IconUsers size={16} />}
                  >
                    {t('admin.userManagement', 'User Management')}
                  </Button>
                  <Button
                    color="red"
                    variant="light"
                    onClick={open}
                    leftSection={<IconTrash size={16} />}
                  >
                    {t('admin.deleteUser', 'Delete User')}
                  </Button>
                </Group>

                <Alert
                  variant="light"
                  color="blue"
                  title={t('admin.userManagementInfo', 'User Management Info')}
                  icon={<IconInfoCircle />}
                >
                  {t(
                    'admin.userManagementDescription',
                    'Here you can manage users, their roles, and access levels. Use the User Management page for detailed user operations.',
                  )}
                </Alert>
              </Stack>
            </Tabs.Panel>

            <Tabs.Panel value="nps">
              <NPSAnalytics />
            </Tabs.Panel>
          </Paper>
        </Tabs>

        <Modal
          opened={opened}
          onClose={close}
          title={
            <Group gap="xs">
              <ThemeIcon color="red" variant="light">
                <IconTrash size={16} />
              </ThemeIcon>
              <Text fw={500}>{t('admin.deleteUser', 'Delete User')}</Text>
            </Group>
          }
        >
          <Stack gap="md">
            {deleteError && (
              <Alert
                color="red"
                title={t('admin.error', 'Error')}
                icon={<IconAlertCircle />}
              >
                {deleteError}
              </Alert>
            )}

            <Text size="sm">
              {t(
                'admin.deleteUserWarning',
                'Are you sure you want to delete this user? This action cannot be undone.',
              )}
            </Text>

            <TextInput
              label={t('admin.userEmail', 'User Email')}
              placeholder="example@example.com"
              value={email}
              onChange={(event) => {
                setEmail(event.currentTarget.value);
              }}
              required
            />

            <Group justify="flex-end" mt="md">
              <Button variant="light" onClick={close}>
                {t('labels.cancel', 'Cancel')}
              </Button>
              <Button
                color="red"
                onClick={handleDeleteUser}
                disabled={!email}
                leftSection={<IconTrash size={16} />}
              >
                {t('admin.confirmDelete', 'Confirm Delete')}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Stack>
    </Container>
  );
};

export default AdminPage;
