/* eslint-disable i18next/no-literal-string */
import {
  Box,
  Card,
  Grid,
  Group,
  Paper,
  RingProgress,
  Stack,
  Table,
  Text,
  Title,
  rem,
  ThemeIcon,
  Badge,
  ScrollArea,
  SegmentedControl,
  Center,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useTranslation } from 'react-i18next';
import { useNpsResponses, useNpsStats } from '../../queries/NpsQueries';
import { useMemo, useState } from 'react';
import {
  IconMoodHappy,
  IconMoodNeutral,
  IconMoodSad,
} from '@tabler/icons-react';
import dayjs from 'dayjs';

const timeRanges = [
  { label: 'All Time', value: 'all' },
  { label: 'Last 30 Days', value: '30d' },
  { label: 'Last 90 Days', value: '90d' },
  { label: 'Custom', value: 'custom' },
];

export const NPSAnalytics = () => {
  const { t } = useTranslation();
  const [timeRange, setTimeRange] = useState('all');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const dateRange = useMemo(() => {
    if (timeRange === 'custom') {
      return {
        startDate: startDate || undefined,
        endDate: endDate || undefined,
      };
    }

    if (timeRange === '30d') {
      const date = new Date();
      date.setDate(date.getDate() - 30);
      return { startDate: date };
    }

    if (timeRange === '90d') {
      const date = new Date();
      date.setDate(date.getDate() - 90);
      return { startDate: date };
    }

    return {};
  }, [timeRange, startDate, endDate]);

  const { data: stats } = useNpsStats(dateRange);
  const { data: responses } = useNpsResponses(dateRange);

  const getScoreColor = (score: number) => {
    if (score <= 6) return 'red';
    if (score <= 8) return 'yellow';
    return 'green';
  };

  const getMoodIcon = (score: number) => {
    if (score <= 6) return <IconMoodSad size={24} />;
    if (score <= 8) return <IconMoodNeutral size={24} />;
    return <IconMoodHappy size={24} />;
  };

  return (
    <Stack gap="xl">
      <Group justify="space-between">
        <Title order={3}>{t('nps.analytics.title', 'NPS Analytics')}</Title>
        <Group>
          <SegmentedControl
            value={timeRange}
            onChange={setTimeRange}
            data={timeRanges}
          />
          {timeRange === 'custom' && (
            <Group>
              <DatePickerInput
                value={startDate}
                onChange={setStartDate}
                label={t('labels.startDate', 'Start Date')}
                clearable
              />
              <DatePickerInput
                value={endDate}
                onChange={setEndDate}
                label={t('labels.endDate', 'End Date')}
                clearable
                minDate={startDate || undefined}
              />
            </Group>
          )}
        </Group>
      </Group>

      <Grid>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Card withBorder padding="xl">
            <Stack gap="md" align="center">
              <RingProgress
                size={160}
                thickness={16}
                sections={[
                  {
                    value:
                      ((stats?.promoters || 0) / (stats?.totalResponses || 1)) *
                      100,
                    color: 'green',
                  },
                  {
                    value:
                      ((stats?.passives || 0) / (stats?.totalResponses || 1)) *
                      100,
                    color: 'yellow',
                  },
                  {
                    value:
                      ((stats?.detractors || 0) /
                        (stats?.totalResponses || 1)) *
                      100,
                    color: 'red',
                  },
                ]}
                label={
                  <Center>
                    <Stack gap={0} align="center">
                      <Text size="xl" fw={700}>
                        {stats?.npsScore || 0}
                      </Text>
                      <Text size="xs" c="dimmed">
                        NPS Score
                      </Text>
                    </Stack>
                  </Center>
                }
              />
              <Stack gap={4} align="center">
                <Text size="sm">
                  {t('nps.analytics.totalResponses', 'Total Responses')}:{' '}
                  <Text span fw={500}>
                    {stats?.totalResponses || 0}
                  </Text>
                </Text>
                <Text size="sm">
                  {t('nps.analytics.averageScore', 'Average Score')}:{' '}
                  <Text span fw={500}>
                    {stats?.averageScore || 0}
                  </Text>
                </Text>
              </Stack>
            </Stack>
          </Card>
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 8 }}>
          <Card withBorder padding="xl">
            <Stack gap="md">
              <Group gap="xl">
                <Box>
                  <Text size="sm" c="dimmed">
                    {t('nps.analytics.promoters', 'Promoters')} (9-10)
                  </Text>
                  <Group gap="xs">
                    <ThemeIcon color="green" variant="light">
                      <IconMoodHappy size={20} />
                    </ThemeIcon>
                    <Text size="xl" fw={700}>
                      {stats?.promoters || 0}
                    </Text>
                    <Text size="sm" c="dimmed">
                      (
                      {Math.round(
                        ((stats?.promoters || 0) /
                          (stats?.totalResponses || 1)) *
                          100,
                      )}
                      %)
                    </Text>
                  </Group>
                </Box>

                <Box>
                  <Text size="sm" c="dimmed">
                    {t('nps.analytics.passives', 'Passives')} (7-8)
                  </Text>
                  <Group gap="xs">
                    <ThemeIcon color="yellow" variant="light">
                      <IconMoodNeutral size={20} />
                    </ThemeIcon>
                    <Text size="xl" fw={700}>
                      {stats?.passives || 0}
                    </Text>
                    <Text size="sm" c="dimmed">
                      (
                      {Math.round(
                        ((stats?.passives || 0) /
                          (stats?.totalResponses || 1)) *
                          100,
                      )}
                      %)
                    </Text>
                  </Group>
                </Box>

                <Box>
                  <Text size="sm" c="dimmed">
                    {t('nps.analytics.detractors', 'Detractors')} (0-6)
                  </Text>
                  <Group gap="xs">
                    <ThemeIcon color="red" variant="light">
                      <IconMoodSad size={20} />
                    </ThemeIcon>
                    <Text size="xl" fw={700}>
                      {stats?.detractors || 0}
                    </Text>
                    <Text size="sm" c="dimmed">
                      (
                      {Math.round(
                        ((stats?.detractors || 0) /
                          (stats?.totalResponses || 1)) *
                          100,
                      )}
                      %)
                    </Text>
                  </Group>
                </Box>
              </Group>
            </Stack>
          </Card>
        </Grid.Col>
      </Grid>

      <Paper withBorder p="md">
        <Stack gap="md">
          <Title order={4}>{t('nps.analytics.responses', 'Responses')}</Title>
          <ScrollArea>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>{t('labels.user', 'User')}</Table.Th>
                  <Table.Th>{t('labels.score', 'Score')}</Table.Th>
                  <Table.Th>{t('labels.feedback', 'Feedback')}</Table.Th>
                  <Table.Th>{t('labels.date', 'Date')}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {responses?.map((response) => (
                  <Table.Tr key={response.id}>
                    <Table.Td>
                      <Stack gap={2}>
                        <Text size="sm" fw={500}>
                          {response.user.firstName
                            ? `${response.user.firstName} ${response.user.lastName || ''}`
                            : response.user.email}
                        </Text>
                        <Text size="xs" c="dimmed">
                          {response.user.email}
                        </Text>
                      </Stack>
                    </Table.Td>
                    <Table.Td>
                      <Group gap="xs">
                        <ThemeIcon
                          size="sm"
                          variant="light"
                          color={getScoreColor(response.score)}
                        >
                          {getMoodIcon(response.score)}
                        </ThemeIcon>
                        <Badge color={getScoreColor(response.score)}>
                          {response.score}
                        </Badge>
                      </Group>
                    </Table.Td>
                    <Table.Td style={{ maxWidth: rem(400) }}>
                      {response.feedback ? (
                        <Text size="sm" lineClamp={2}>
                          {response.feedback}
                        </Text>
                      ) : (
                        <Text size="sm" c="dimmed" fs="italic">
                          {t('nps.noFeedback', 'No feedback provided')}
                        </Text>
                      )}
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm">
                        {dayjs(response.createdAt).fromNow()}
                      </Text>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </ScrollArea>
        </Stack>
      </Paper>
    </Stack>
  );
};
